@import './colors';

.modal-content {
  .modal-header {
    background: #f1f9ff;
    border-bottom-color: #ccddea;

    .modal-title {
      font-size: 1.1rem;
      color: rgb(var(--primary));
    }

    button {
      outline: none;
    }
  }

  .modal-footer {
    background: #f1f9ff;
    border-top-color: #ccddea;
  }
}
