/* You can add global styles to this file, and also import other style files */
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/material.css';
@import './app/styles/colors';
@import './app/styles/modal';
@import './app/styles/list-group';

// Your variable overrides

// Bootstrap and its default variables
@import '../node_modules/bootstrap/scss/bootstrap';

// Main styles
@import './app/styles/styles-base';

// regular style toast
@import '~ngx-toastr/toastr';

@import '~@ng-select/ng-select/themes/default.theme.css';
