// top bar
$topBarBg: rgb(var(--topBarBg));
$topBarLinkText: rgb(var(--topBarLinkText));
$topBarHoverBg: rgba(var(--topBarHoverBg), 0.08);
$topBarHoverUnderline: rgb(var(--topBarHoverUnderline));
$topBarUserName: rgba(var(--topBarUserName), 0.9);
$topBarCompanyName: rgba(var(--topBarCompanyName), 0.9);

$topBarDropdownTopBorder: rgb(var(--topBarDropdown));
$topBarDropdownHover: rgba(var(--topBarDropdown), 0.08);
$topBarDropdownFocusText: rgb(var(--topBarDropdown));

// map bradcrumbs
$breadcrumbBg: rgb(var(--breadcrumbBg));
$breadcrumbMapIcon: rgba(var(--breadcrumbIcon), 0.4);
$breadcrumbSeparatorIcon: rgba(var(--breadcrumbIcon), 0.4);
$breadcrumbText: rgb(var(--breadcrumbText));
$breadcrumbFocusText: rgb(var(--breadcrumbText));

// map search
$mapSearchBarBg: #f7f7f7;
$mapSearchBarText: rgb(var(--darkGrey));
$mapSearchBarPlaceholder: rgba(var(--darkGrey), 0.5);
$mapSearchBarIcon: rgb(var(--darkGrey));

// map headers
$categoryHeader: rgb(var(--darkGrey));

// map list
$mapListItemIcon: rgb(var(--primary));
$mapListItemText: rgb(var(--darkGrey));
$mapListItemTitleTextHover: var(--primary);
$mapListItemHoverBg: rgba(var(--primary), 0.18);
$mapListItemFocusBorder: var(--primary);
$mapListItemBorder: rgba(var(--primary), 0.08);

// map categories
$mapCategoryHoverBorder: rgb(var(--primary));
$mapCategoryHoverBg: rgba(var(--primary), 0.08);
$mapCategoryHoverText: var(--primary);

// page header
$headerText: rgb(var(--mainTextColor));
$headerSubText: rgb(var(--mainTextColor));

// grid search bar
$gridSearchBarBg: rgba(var(--primary), 0.08);
$gridSearchBarIcon: rgb(var(--primary));
$gridSearchBarText: rgb(var(--primary));
$gridSearchBarPlaceholder: rgba(var(--primary), 0.5);

// grid drag and drop
$gridDragAndDropBg: rgb(var(--offWhite));
$gridDragAndDropBgHover: rgba(var(--primary), 0.15);
$gridDragAndDropText: rgba(var(--darkGrey), 0.5);
$gridDragAndDropGroupBg: rgb(var(--primary));
$gridDragAndDropGroupText: rgb(var(--white));
$gridDragAndDropGroupBgHover: rgb(var(--primary));

// grid header
$gridHeaderCellBg: rgb(var(--primary));
$gridHeaderCellBorderBottom: rgb(var(--primary-darkest));
$gridHeaderCellBorderRight: rgb(var(--primary-lighter));
$gridCellNoSortBg: rgb(var(--primary-darker));
$gridCellSortHoverBg: rgb(var(--primary-lighter));
$gridHeaderCellText: rgb(var(--white));
$gridCellHeaderIcons: rgb(var(--white));

// grid rows
$gridBodyRowHoverBg: rgba(var(--primary), 0.08);
$gridBodyRowHoverText: rgb(var(--primary));

// links
$linkText: rgb(var(--primary));
$linkTextHover: rgb(var(--primary-darkest));

// grid pagination
$gridSpinnerColor: rgb(var(--secondary));

$gridPagerBg: rgba(var(--primary), 0.08);
$gridPagerText: rgb(var(--primary));
$gridPagerFocusBg: rgb(var(--primary));
$gridPagerFocusText: rgb(var(--white));
$gridPagerHoverBg: rgba($white, 0.8);

// buttons
$buttonBg: rgb(var(--primary));
$buttonHover: rgb(var(--primary-darkest));
$buttonText: rgb(var(--white));
$cardButtonBg: rgb(var(--white));
$cardButtonText: rgb(var(--primary));
$cardButtonHoverBg: rgb(var(--white));
$cardButtonHoverText: rgb(var(--primary-darkest));

// labels
$labelText: rgb(var(--primary));

// card
$cardGradientBottomLeft: rgb(var(--primary-darker));
$cardGradientTopRight: rgb(var(--primary));
$cardHeaderText: rgb(var(--white));
$cardSideGradientBottomLeft: rgb(var(--offWhite));
$cardSideGradientTopRight: rgb(var(--offWhite-darker));
$cardSideGradientIcon: rgb(var(--primary));

$cardTabBg: rgb(var(--white));
$cardTabText: #6c757d;
$cardTabHoverBg: #f1f2f3;
$cardTabHoverText: rgb(var(--darkGrey));
$cardTabActiveBg: rgb(var(--white));
$cardTabActiveText: rgb(var(--darkGrey));

// forms
$labelText: rgb(var(--darkGrey));
