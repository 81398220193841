$blue: #1690d0;
$blueHoverBg: rgba(22, 144, 208, 0.08);
$white: #fff;
$lightGrey: #d9dad9;
$darkGrey: #898b8e;
$offWhite: #f7f7f7;
$red: #ff3d71;
$darkBlue: #104573;
$blueGrey: #5685a9;

$background: #fafbfc;
$border: #e0e0e0;

$primary: $blue;
$primaryHoverBg: $blueHoverBg;
$secondary: $darkBlue;
$bodyText: rgb(var(--mainTextColor));
$danger: $red;

$gridEvenRow: #f7f7f7;
