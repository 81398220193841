@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import './brand';

* {
  font-family: 'Roboto';
}

body {
  color: rgb(var(--mainTextColor));
}

html {
  background: $background;
  color: $bodyText;
}

:focus {
  outline: none;
}

.list-group-flush {
  border-radius: 4px;
  overflow: hidden;
}

.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background: rgb(var(--primary));
}

.e-checkbox-wrapper:hover .e-frame.e-check,
.e-css.e-checkbox-wrapper:hover .e-frame.e-check {
  background: rgb(var(--secondary));
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-active > .e-text-content,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text {
  color: rgb(var(--primary));
}

.pac-container {
  z-index: 10000 !important;
}

::ng-deep .pac-container {
  position: fixed !important;
}

.card {
  border: none;
}

.btn.btn-primary {
  background: $buttonBg;
  border-color: $buttonBg;
  color: $buttonText;

  &:hover {
    background: $buttonHover;
    border-color: $buttonHover;
  }
}

.card-header {
  background: rgb(var(--secondary));
  border: none;
  color: white;
  border: none;

  &:not(.default) {
    background: linear-gradient(
      45deg,
      $cardGradientBottomLeft,
      $cardGradientTopRight
    );
  }

  a {
    color: $white;
  }

  .btn-primary {
    background: $cardButtonBg;
    color: $cardButtonText;
    border: none;
    margin-right: -7px;

    &:hover {
      background: $cardButtonHoverBg;
      color: $cardButtonHoverText;
    }

    &:not(.disabled):active {
      background: $cardButtonBg;
      color: $cardButtonText;
    }
  }
}

a {
  color: $linkText;

  &:hover {
    text-decoration: none;
    color: $linkTextHover;
  }
}

label,
dt {
  font-size: 0.85rem;
  font-weight: bold;
  color: $labelText;
}

.card.card-light-grey {
  background: #f1f2f3;
  border: 1px solid darken(#f1f2f3, 8%);
}

.gm-style-iw-t {
  right: 0px !important;
  bottom: 55px !important;
  left: 4px !important;
}

.spinner-grow {
  color: rgb(var(--primary));
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.agm-info-window-content {
  h5 {
    padding: 10px 30px;
    background-color: rgb(var(--primary));
    color: $white;
    margin: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  hr {
    margin: 0;
    margin-bottom: 10px;
  }
  p {
    padding: 0 30px;
  }
}

// padding: 0;
//     .si-close-button {
//       z-index: 100;
//       color: white;
//       margin: 7px 0;
//       opacity: 1;
//       &:hover {
//         opacity: 0.5;
//       }
//     }
//     .si-content, .agm-info-window-content {

//     }

.mt-neg-1 {
  margin-top: -1.25rem !important;
}

.e-grid .e-toolbar .e-btn {
  .e-icons,
  .e-tbar-btn-text {
    color: rgb(var(--mainTextColor));
  }
}

.e-grid .e-emptyrow:not(.e-editedbatchcell) {
  color: #898b8e;
}

.e-grid .e-headertext {
  color: rgb(var(--primary));
}

.e-checkbox-wrapper .e-label,
.e-css.e-checkbox-wrapper .e-label,
.e-input-group,
.e-input-group.e-control-wrapper,
.e-treeview .e-list-text,
.form-control {
  color: rgb(var(--mainTextColor));
}

img.header-asset-type {
  height: 50px;
  width: 75px;
}
img.header-asset-image {
  height: 50px;
  width: 50px;
}
img.custom-icon {
  width: 43px !important;
  display: inline-block !important;
}

p.custom-p {
  padding: 0 !important;
  &.pl-3 {
    padding-left: 1.25rem !important;
  }
}
