@import './colors';

.list-group.switch {
  .list-group-item {
    padding: 6px 15px;
    cursor: pointer;

    &:not(.active):hover {
      color: rgb(var(--primary));
    }

    &.active {
      background: rgb(var(--primary));
      border-color: rgb(var(--primary));
    }
  }
}
